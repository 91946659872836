<template>
  <div id="app">
    <NavigationBar
      class="navbar--border-bottom"
      sub-logo-src="images/sublogo.svg"
    />

    <DefaultContentLayout />

    <Footer class="mt-auto" />
  </div>
</template>

<script>
import NavigationBar from 'chimera/all/themes/waffle/components/layout/NavigationBar'
import Footer from 'chimera/all/themes/waffle/components/layout/Footer'
import DefaultContentLayout from 'chimera/all/themes/waffle/layouts/DefaultContentLayout'

export default {
  name: 'ContentLayout',

  components: {
    DefaultContentLayout,
    Footer,
    NavigationBar,
  },
}
</script>
