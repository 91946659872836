<template>
  <main>
    <t-progress-bar :color="progressBarColor" />

    <FormContainer>
      <nuxt />
    </FormContainer>
  </main>
</template>

<script>
import FormContainer from 'chimera/all/components/form/FormContainer'

export default {
  name: 'DefaultFormLayout',

  components: {
    FormContainer,
  },

  props: {
    progressBarColor: {
      type: String,
      default: 'secondary',
    },
  },
}
</script>
