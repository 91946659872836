<template>
  <div id="app">
    <NavigationBar sub-logo-src="images/sublogo.svg" />

    <DefaultFormLayout />

    <Footer class="mt-auto" />
  </div>
</template>

<script>
import Footer from 'chimera/all/themes/waffle/components/layout/Footer'
import DefaultFormLayout from 'chimera/all/themes/waffle/layouts/DefaultFormLayout'
import NavigationBar from 'chimera/all/themes/waffle/components/layout/NavigationBar'

export default {
  name: 'FormLayout',

  components: {
    DefaultFormLayout,
    Footer,
    NavigationBar,
  },
}
</script>
