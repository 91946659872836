<template>
  <main>
    <div class="container sm:max-w-2xl">
      <t-btn
        id="contentBackButton"
        class="my-3 t-btn--outlined"
        color="primary"
        @click="$router.go(-1)"
      >
        Terug
      </t-btn>
    </div>

    <nuxt />
  </main>
</template>

<script>
export default {
  name: 'DefaultContentLayout',
}
</script>
