<template>
  <footer class="footer" :class="[colorVariant]">
    <div v-if="topLink" class="footer__top-link">
      <nuxt-link :id="topLink.id" v-track-click class="link" :to="topLink.to">
        {{ topLink.text }}
      </nuxt-link>
    </div>

    <div class="footer__links">
      <template v-for="(link, i) of links">
        <nuxt-link
          :id="link.id"
          :key="i"
          v-track-click
          class="link"
          :to="link.to"
        >
          {{ link.text }}
        </nuxt-link>
        <span v-if="!isLastItem(i)" :key="`separator-${i}`" class="separator"
          >|</span
        >
      </template>
    </div>
  </footer>
</template>

<script>
import usesColor from 'chimera/all/mixins/usesColor'

export default {
  name: 'Footer',

  mixins: [usesColor],

  props: {
    topLink: {
      type: Object,
      default: () => {
        return {
          id: 'contact-pro',
          text: 'Aanmelden als bedrijf',
          to: '/contact-opnemen-bedrijf',
        }
      },
    },

    links: {
      type: Array,
      default: () => [
        {
          id: 'privacy',
          text: 'Privacy',
          to: '/privacy',
        },
        {
          id: 'cookies',
          text: 'Cookies',
          to: '/cookies',
        },
        {
          id: 'cookies',
          text: 'Algemene voorwaarden',
          to: '/algemene-voorwaarden',
        },

        {
          id: 'contact',
          text: 'Contact',
          to: '/contact-opnemen',
        },
      ],
    },

    color: {
      type: String,
      default: 'secondary',
    },
  },

  methods: {
    /**
     * Check if it is the last item within the list
     *
     * @param {number} index
     * @returns {boolean}
     */
    isLastItem(index = 0) {
      return index === this.links.length - 1
    },
  },
}
</script>
