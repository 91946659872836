<template>
  <nav>
    <div class="container navbar">
      <nuxt-link
        id="navHome"
        v-track-click
        class="navbar__title navbar__link"
        to="/"
      >
        <div class="navbar__brand">
          <InlineSvg class="navbar__logo" :src="logoSrc" :alt="title" />
          <InlineSvg
            v-if="subLogoSrc"
            class="navbar__sub-logo"
            :src="subLogoSrc"
            :alt="title"
          />
        </div>
      </nuxt-link>

      <nuxt-link
        id="navHomeIcon"
        v-track-click
        class="navbar__home-icon navbar__link"
        :class="{ hidden: $route.path === '/' }"
        to="/"
      >
        <t-icon color="secondary" :icon="mdiHome" />
      </nuxt-link>
    </div>
  </nav>
</template>

<script>
import { mdiHome } from '@mdi/js'
import InlineSvg from 'vue-inline-svg'

export default {
  name: 'NavigationBar',

  components: {
    InlineSvg,
  },

  props: {
    title: {
      type: String,
      default: process.env.CONCEPT,
    },

    logoSrc: {
      type: String,
      default: 'images/logo.svg',
    },

    subLogoSrc: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    mdiHome,
  }),
}
</script>
